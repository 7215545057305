import React  from "react";
import { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';


// images
import teamviewer_icon from "../images/TeamViewer_Logo.png";
import chatbot_img from "../images/chatbot.png";

function Support() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(()=>{setLoading(false)}, 1500)
    }, [])


    return(
        <Layout>
            <Helmet>
                <title>Support | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Välkommen till OptoSwedens egna supportavdelning som alltid är redo för att hjälpa dig och dina anställda." />
                <meta name="keywords" content="CrossState eVoyce | Spåra faktura | AtWork | Abbyy" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="OptoSwedens egna supportavdelning som alltid är redo för att hjälpa dig och dina anställda." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/support" />
                <link rel="cannonical" href="https://www.optosweden.se/support" />
            </Helmet>

            <section className="border-t border-gray-100 pt-28">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-12 gap-4">
                        <div className="col-span-9">
                            <h1 className="title-font font-semibold text-3xl text-blue-custome">Välkommen till vår support</h1>
                            <p className="mt-8 leading-relaxed font-bold text-lg text-gray-600">Öppettider</p>
                            <p className="leading-relaxed text-lg text-gray-600">
                                Support: mån &#8211; fre, 08.00 &#8211; 17.00 med lunchstängt 12.00-13.00.<br/>
                                Dag före röd dag stänger supporten 14.00, röda dagar är supporten stängd.
                            </p>
                        </div>
                        <div className="hidden col-span-3 lg:flex justify-end" >
                            <a  className="h-16 w-44 flex items-center border border-gray-200 rounded-lg shadow-md hover:shadow-lg p-2"  href="https://get.teamviewer.com/osv12" target="_blank" rel="noreferrer">
                                <img className="w-12 h-12" src={teamviewer_icon} alt="" />
                                <p className="px-2 text-center text-gray-600">Ladda ner Team Viewer</p>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-20 pb-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="w-full relative" style={{"minHeight": "900px"}}>
                        <div className="lg:absolute left-0 top-0 w-full lg:w-9/12 xl:w-8/12 2xl:w-7/12">
                            {loading ?
                                <div>
                                    <div className="flex gap-5 items-center">
                                        <img className="w-12 h-12" src={chatbot_img} alt="chatbot" />
                                        <div class="bg-white rounded-md p-2 flex gap-2 shadow-xl border border-gray-100">
                                            <div class="w-4 h-4 rounded-full animate-pulse bg-blue-500"></div>
                                            <div class="w-4 h-4 rounded-full animate-pulse bg-blue-600"></div>
                                            <div class="w-4 h-4 rounded-full animate-pulse bg-blue-700"></div>
                                        </div>
                                    </div>
                                </div> 
                            :
                                <div>
                                    <div className="flex gap-5 items-start">
                                        <img className="w-12 h-12" src={chatbot_img} alt="chatbot" />
                                        <div className="transition ease-in duration-200 rounded-md">
                                            <p className="bg-white border border-gray-100 p-2 shadow-xl rounded-md">Välkommen till Optoswedens egna supportavdelning som alltid är redo för att hjälpa dig och dina anställda 👋 Vad kan vi hjälpa dig med?</p>
                                        </div>
                                    </div>

                                    <div className="mt-6 w-full" style={{"height": "800px"}}>
                                        <iframe title="optosweden-support" className="w-full h-full" src="https://online3.superoffice.com/Cust27923/CS/scripts/customer.fcgi?action=formFrame&formId=F-LrezXjyQ" />
                                    </div>
                                </div>
                            }
                            
                            
                        </div>
                        <div className="w-full h-full flex items-end justify-end">
                            <div className="min-h-36 py-40 lg:py-96 w-9/12 lg:w-5/12 bg-support-girl bg-no-repeat bg-contain bg-right-top">
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </Layout>
    );
}
export default Support;